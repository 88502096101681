import React from "react";

export const Layout: React.FunctionComponent = ({ children }) => {
  return (
    <>
      {/* Header */}
      {children}
      {/* Footer */}
    </>
  );
};
